import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AppHeader = makeShortcode("AppHeader");
const Downloads = makeShortcode("Downloads");
const AppFeature = makeShortcode("AppFeature");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AppHeader description="Harbor is the modern feed reader you didn’t know you needed. Follow the people, blogs, newsletters, and RSS feeds you care about, with no ads and no algorithms. For iOS, iPadOS, and macOS." mdxType="AppHeader" />
    <Downloads mdxType="Downloads" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1430880c4fbec53b72d8a50f50708e2b/e93cc/HarborHero.webp 300w", "/static/1430880c4fbec53b72d8a50f50708e2b/b0544/HarborHero.webp 600w", "/static/1430880c4fbec53b72d8a50f50708e2b/68fc1/HarborHero.webp 1200w", "/static/1430880c4fbec53b72d8a50f50708e2b/a2303/HarborHero.webp 1800w", "/static/1430880c4fbec53b72d8a50f50708e2b/4293a/HarborHero.webp 2400w", "/static/1430880c4fbec53b72d8a50f50708e2b/b2d16/HarborHero.webp 3500w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1430880c4fbec53b72d8a50f50708e2b/eed55/HarborHero.png 300w", "/static/1430880c4fbec53b72d8a50f50708e2b/7491f/HarborHero.png 600w", "/static/1430880c4fbec53b72d8a50f50708e2b/8537d/HarborHero.png 1200w", "/static/1430880c4fbec53b72d8a50f50708e2b/d2cc9/HarborHero.png 1800w", "/static/1430880c4fbec53b72d8a50f50708e2b/00711/HarborHero.png 2400w", "/static/1430880c4fbec53b72d8a50f50708e2b/9697f/HarborHero.png 3500w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/1430880c4fbec53b72d8a50f50708e2b/8537d/HarborHero.png",
            "alt": "Harbor’s home feed on iPhone, iPad, and Mac",
            "title": "Harbor’s home feed on iPhone, iPad, and Mac",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <AppFeature title="One timeline for all your interests" iconName="Merge" mdxType="AppFeature">
      <p>{`Harbor smartly combines posts from all the feeds you follow into a unified timeline.`}</p>
      <p>{`We don’t like having to bounce between various individual RSS feeds. Harbor is designed to keep you in one place.`}</p>
      <p>{`Some feeds post really frequently, and others rarely. Harbor can handle that. Mark a feed as “completionist” and we’ll make sure you don’t miss a post from that blogger you really care about.`}</p>
    </AppFeature>
    <AppFeature title="iCloud sync" iconName="Sync" mdxType="AppFeature">
      <p>{`No need to subscribe to a third-party feed syncing service. Harbor quickly syncs your subscriptions between all your devices. No signup or login required.`}</p>
    </AppFeature>
    <AppFeature title="Explore the world (of feeds)" iconName="Binoculars" screenshot="explore" mdxType="AppFeature">
      <p>{`Looking for something new? Just hearing about RSS? Check out our finely curated library of feeds.`}</p>
      <p>{`The Explore page is a great place to find blogs, publications, and newsletters you might not have heard of.`}</p>
    </AppFeature>
    <AppFeature title="Photo carousels" iconName="Column" screenshot="carousel" flip mdxType="AppFeature">
      <p>{`Photo-only posts are displayed in an attractive and compact horizontal carousel. The carousel is perfect for keeping updated on photographers or daily comics without cluttering your vertical feed.`}</p>
    </AppFeature>
    <AppFeature title="Themes" iconName="FirstQuarterMoon" screenshot="themes" mdxType="AppFeature">
      <p>{`Harbor supports themes and customization. We respect your system font size choice, and you can pick the title font size that suits your taste.`}</p>
    </AppFeature>
    <AppFeature title="Syntax highlighting" iconName="Code" screenshot="syntax" flip mdxType="AppFeature">
      <p>{`Enjoy reading about programming? Posts that include source code will get beautiful syntax highlighting in Harbor’s viewer.`}</p>
      <p>{`Harbor can highlight HTML/CSS, JS, TypeScript, JSON, GraphQL, Swift, Rust, Go, Python, and Ruby.`}</p>
    </AppFeature>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      